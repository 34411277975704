import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, isRef as _isRef, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/image/timetable/tip_close.png'
import _imports_1 from '@/assets/image/timetable/no_topic_tip.png'


const _hoisted_1 = { class: "CourseTopic2" }
const _hoisted_2 = { class: "pans" }
const _hoisted_3 = { class: "pan-content" }
const _hoisted_4 = { class: "nav" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { style: {"position":"relative"} }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "topic_choose_alert" }
const _hoisted_10 = { class: "topic_choose_alert--title" }
const _hoisted_11 = { class: "topic_choose_alert--classtimes" }
const _hoisted_12 = { class: "topic_choose_alert__topic" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { style: {"position":"relative","margin":"10px"} }
const _hoisted_15 = ["src", "alt"]
const _hoisted_16 = { class: "topic-select__title" }
const _hoisted_17 = { key: 1 }

import GoBackIcon from "@/components/system/GoBackIcon.vue";
import LogoutIcon from "@/components/system/LogoutIcon.vue";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import service from "@/utils/axios";
import {useRoute, useRouter} from "vue-router";
import {message, Modal as AModal} from "ant-design-vue";
import numberDigit from "@/utils/customComponentAPI/chineseNum2Num";
import moment from "moment";
import {decryptByDES} from '@/utils/DES'
import {onPageLoadingStart} from "@/utils/DLoading";
import CourseTextColl from "@/components/timetable/CourseTextColl.vue";

interface ICourseTopicRef{
  list: Array<any>,
  modalList: Array<any>,
  chooseClasstime: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseTopic2',
  setup(__props) {


const route = useRoute()
const router = useRouter()

let visible = ref(false)
let screenWidth = ref(window.innerWidth)

const onResetScreen = () => {
  screenWidth.value = window.innerWidth
}

let dataRef = reactive({
  list: [],
  modalList: [],
  chooseClasstime: null
}) as ICourseTopicRef

let watchIndex = ref(0)

const getTopicList = async (courseId) => {
  if(!courseId){
    onPageLoadingStart('获取课题中...')
    return;
  }
  const r:any = await service.get('/dwart/an_teaching/classCourse/queryCourseTopicByCourseId', {
    params: {
      // courseId:,
      queryType: 2,
      lessonId: courseId,
    }
  })
  if (r.length > 1) {
    dataRef.modalList = r;
    visible.value = true
  } else if (r.length === 0) {
    visible.value = true
  } else {
    await onCardClick(r[0])
  }

}


const onCardClick = async (item) => {
  if (item.forbid) {
    message.error('此课件已经下架');
    return false;
  }
  await router.push({
    name: 'Courseware',
    query: {
      from: 'subject',
      topicId: item.topicId,
      topicName: item.topicName,
    }
  })
}


const getData = async () => {
  const r:any = await service.get('/dwart/an_teaching/classCourse/queryGameScen', {
    params: {
      classId: route.query.id,
      queryType: 2
    }
  })

  let d = r.map(v => {
    return {
      id: v.id,
      uid: v.cId,
      cWidth: v.cWidth,
      cHeight: v.cHeight,
      bg: v.sceneUrl,
      npcs: v.npcs,
    }
  })
  dataRef.list = d;
}

const onTextClick = (item) => {
  dataRef.chooseClasstime = item;
  if (!item.courseDto){
    visible.value = true
    return
  }
  getTopicList(item.courseDto.scheduleId)
}

const onPanchange = (num) => {
  if (num === -1 && watchIndex.value === 0) {
    return
  }
  if (num === 1 && watchIndex.value === dataRef.list.length - 1) {
    return;
  }
  watchIndex.value += num
}

onMounted(() => {
  getData()
  window.onresize = () => {
    onResetScreen()
  }
})

onUnmounted(() => {
  window.onresize = null
})







return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(GoBackIcon),
      _createVNode(LogoutIcon)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataRef).list, (i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i.uid,
          class: "pan-item",
          style: _normalizeStyle({
             background: `url(${i.bg}) no-repeat`,
             backgroundSize: 'cover',
             transform: `translateX(${-_unref(screenWidth) * _unref(watchIndex)}px)`
           })
        }, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.npcs, (ii) => {
              return (_openBlock(), _createElementBlock("div", {
                key: ii.uid,
                class: "pan-content--component",
                style: _normalizeStyle({
                top: ii.posY * 2 + 'px',
                left: ii.posX * 2 + 'px'
               })
              }, [
                _createVNode(CourseTextColl, {
                  "text-data": ii,
                  onClick: onTextClick,
                  "is-subject": ""
                }, null, 8, ["text-data"])
              ], 4))
            }), 128))
          ])
        ], 4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: _normalizeClass(["nav-item__icon", {'active': _unref(watchIndex) !== 0}]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (onPanchange(-1)))
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass(["nav-item__icon", {'active': _unref(watchIndex) !== _unref(dataRef).list.length - 1}]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (onPanchange(1)))
        }, null, 2)
      ])
    ]),
    _createVNode(_unref(AModal), {
      visible: _unref(visible),
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      centered: "",
      width: _unref(dataRef).chooseClasstime && _unref(dataRef).chooseClasstime.courseDto ? 1200: 694,
      maskClosable: false,
      footer: null,
      wrapClassName: "modal-background-opacity",
      closable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            style: {"position":"absolute","cursor":"pointer","right":"-60px"},
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_isRef(visible) //@ts-ignore
 ? visible.value = false : visible = false))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              draggable: "false"
            }, null, -1)
          ])),
          (_unref(dataRef).chooseClasstime && _unref(dataRef).chooseClasstime.courseDto)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, " 第" + _toDisplayString(_unref(dataRef).chooseClasstime.courseDto.courseIndex) + "课 ", 1),
                  _createElementVNode("div", _hoisted_11, " 上课时间：" + _toDisplayString(_unref(moment)(_unref(dataRef).chooseClasstime.courseDto.bigDate).format('M月DD日')) + "（" + _toDisplayString(_unref(dataRef).chooseClasstime.courseDto.startTime) + "-" + _toDisplayString(_unref(dataRef).chooseClasstime.courseDto.endTime) + "） ", 1),
                  _createElementVNode("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataRef).modalList, (i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: i.topicId,
                        class: "topic-select",
                        onClick: ($event: any) => (onCardClick(i))
                      }, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("img", {
                            src: _unref(decryptByDES)(i.topicIcon),
                            alt: i.topicName,
                            style: {"width":"320px","height":"240px","border-radius":"30px","object-fit":"cover"}
                          }, null, 8, _hoisted_15),
                          _createElementVNode("div", _hoisted_16, _toDisplayString(i.topicName), 1)
                        ])
                      ], 8, _hoisted_13))
                    }), 128))
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[5] || (_cache[5] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "",
                  draggable: "false"
                }, null, -1)
              ])))
        ])
      ]),
      _: 1
    }, 8, ["visible", "width"])
  ]))
}
}

})